import React, { useState } from 'react'
import '../loader.css'

import Button from 'react-bootstrap/Button'
import { Form } from 'react-bootstrap'
import { Container, Row, Col } from 'react-bootstrap'

// API call:
const baseId_MC = process.env.REACT_APP_BASE_ID_MC
const apiPT_MC = process.env.REACT_APP_API_PT_MC
const table_MC = process.env.REACT_APP_TABLE_MC
const apiUrl_MC = `https://api.airtable.com/v0/${baseId_MC}/${table_MC}`

/* Controlled Input Form */
export const MasterClassForm = () => {
  // state management
  const [submitting, setSubmitting] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [showForm, setShowForm] = useState(true)
  // state - form data
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [tasting, setTasting] = useState('')
  const [message, setMessage] = useState('')
  const [gradeTotal, setGradeTotal] = useState(4)
  const [gradeInspire, setGradeInspire] = useState(4)
  const [gradeRecommend, setGradeRecommend] = useState(4)

  // filter input email from '!'
  const handleChange = (e) => {
    setEmail(e.target.value.replace(/!/g, ''))
  }

  const postFormData = async (event) => {
    console.log('postFormData triggered ✅')

    const data = {
      fields: {
        name: name,
        email: email,
        tasting_name: tasting,
        grade_total: Number(gradeTotal),
        grade_inspire: Number(gradeInspire),
        grade_recommend: Number(gradeRecommend),
        notes: message,
      },
    }
    // console.log(data)

    await fetch(apiUrl_MC, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${apiPT_MC}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      // Checking that the fetch was successful
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was Error ⚠️')
        } else {
          console.log('Network response was ok ✅')
          return response.json()
        }
      })

      .then((data) => {
        // console.log('Success ✅, posted this:', data)
        setSubmitting(false)
        // setShowForm(false)
        setSubmitSuccess(true)
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }

  // function to handle submit on user click
  const handleSubmit = async (event) => {
    // console.log('input in form:', name, email, message)
    setSubmitting(true) // Show text submitting
    setShowForm(false)
    postFormData(event) // Trigger fetch API for post
    event.preventDefault() // Disable reload on keystrokes
  }

  return (
    <Container>
      <Row>
        <Col className='col-md-8 offset-md-2'>
          <div className='card card-outline-secondary'>
            <div className='card-header card-header-purple'>
              <h6 className='lead mb-0'>Dryckesprovning - Online</h6>
            </div>

            <div className='card-body'>
              <Form onSubmit={handleSubmit} className='mt-0'>
                <br />
                <p className='pb-0 pt-0 mb-2 display-4 text-center '>Sweden Wine Club</p>
                {/* <h1 className='pb-2 lead'>Online</h1> */}

                {/* <p className='lead'>Sweden Wine Club</p> */}
                <hr className='mt-2 mb-3' />
                <br />
                {/* Form status - submitting: */}
                {submitting && (
                  <>
                    <section className='text-center'>
                      <h4>Skickar formulär..</h4>
                      <div className='lds-dual-ring'></div>
                    </section>
                  </>
                )}
                {/* Form status - success: */}
                {submitSuccess && (
                  <>
                    <section className='text-center'>
                      <p className='lead'>Formuläret är skickat.</p>
                      <h3 className='display-3'>
                        Tack! <span className='small'>🍾</span>
                      </h3>
                      <p className='lead'>Vi uppskattar din feedback!</p>

                      <p className='lead mt-5'>
                        {' '}
                        <a className='link-purple' href='https://www.swedenwineclub.se' _target='_blank'>
                          {' '}
                          ▶︎ Se Sweden Wine Club för ännu fler härliga upplevelser inom vinets värld!
                        </a>
                      </p>
                    </section>
                  </>
                )}
                {/*  Show form: */}
                {showForm && (
                  <div>
                    <section className='text-left'>
                      <p className='lead pb-4'>
                        Stort tack för att du tar dig tid att svara på några frågor gällande våra dryckesprovningar online. Det ger oss viktiga insikter, så att
                        vi kan göra ännu bättre provningar. Tack!{' '}
                      </p>
                    </section>

                    <hr className='mt-0 mb-4' />

                    {/* Tasting: */}
                    <Form.Group>
                      <Form.Label htmlFor='tasting'>
                        <p className='lead mb-0'>
                          Vilken provning gäller det: <small className='text-muted'> (ex "GRUND RÖTT VIN"")</small>
                        </p>
                      </Form.Label>
                      <Form.Control id='tasting' type='text' value={tasting} onChange={(e) => setTasting(e.target.value)} required />
                    </Form.Group>

                    {/* Grade Total: */}
                    <Form.Group>
                      <Form.Label>
                        <p className='lead mb-0'>
                          Hur tycker du att provningen har varit totalt sett?
                          {/* <small className='text-muted'> (1-5)</small> */}
                        </p>
                      </Form.Label>
                      <Form.Control id='gradeTotal' type='number' value={gradeTotal} onChange={(e) => setGradeTotal(e.target.value)} required as='select'>
                        <option value='5'>5 - Toppklass!</option>
                        <option value='4'>4</option>
                        <option value='3'>3</option>
                        <option value='2'>2</option>
                        <option value='1'>1</option>
                      </Form.Control>
                    </Form.Group>

                    {/* Grade Inspire: */}
                    <Form.Group>
                      <Form.Label>
                        <p className='lead mb-0'>
                          Vilket betyg ger du provningen när det gäller förmågan att inspirera och lära ut?
                          {/* <small className='text-muted'> (1-5)</small> */}
                        </p>
                      </Form.Label>
                      <Form.Control id='gradeInspire' type='number' value={gradeInspire} onChange={(e) => setGradeInspire(e.target.value)} as='select'>
                        <option value='5'>5 - Toppklass!</option>
                        <option value='4'>4</option>
                        <option value='3'>3</option>
                        <option value='2'>2</option>
                        <option value='1'>1</option>
                      </Form.Control>
                    </Form.Group>

                    {/* Grade Recommend: */}
                    <Form.Group>
                      <Form.Label>
                        <p className='lead mb-0'>
                          Hur troligt är det att du skulle rekommendera en av våra provningar till en vän?
                          {/* <small className='text-muted'> (1-5)</small> */}
                        </p>
                      </Form.Label>
                      <Form.Control id='gradeRecommend' type='number' value={gradeRecommend} onChange={(e) => setGradeRecommend(e.target.value)} as='select'>
                        <option value='5'>5 - Mycket troligt</option>
                        <option value='4'>4</option>
                        <option value='3'>3</option>
                        <option value='2'>2</option>
                        <option value='1'>1</option>
                      </Form.Control>
                    </Form.Group>

                    {/* Notes: */}
                    <Form.Group>
                      <Form.Label htmlFor='message'>
                        <p className='lead mb-0'>Vad skulle du vilja utveckla, ändra och förbättra vad gäller provningens innehåll, och varför?</p>
                      </Form.Label>
                      <Form.Control placeholder='...' as='textarea' rows='3' id='message' value={message} onChange={(e) => setMessage(e.target.value)} />
                    </Form.Group>

                    {/* Name: */}
                    <Form.Row>
                      <Form.Group as={Col}>
                        <Form.Label htmlFor='name'>
                          <p className='lead mb-0'>Ditt namn</p>
                        </Form.Label>
                        <Form.Control id='name' type='text' value={name} onChange={(e) => setName(e.target.value)} required />
                      </Form.Group>
                      {/* Email: */}
                      <Form.Group as={Col}>
                        <Form.Label htmlFor='email'>
                          <p className='lead mb-0'>Din epost</p>
                        </Form.Label>
                        <Form.Control id='email' type='email' value={email} onChange={handleChange} required />
                      </Form.Group>
                    </Form.Row>
                    <br />
                    <Button type='submit' variant='purple' block className='mb-4'>
                      Skicka
                    </Button>
                  </div>
                )}
              </Form>
            </div>
          </div>
          <footer className='mt-4 mb-4'>{/* footer comp */}</footer>
        </Col>
      </Row>
    </Container>
  )
}

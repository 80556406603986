import React from 'react'
import { Container, Row, Col, Alert } from 'react-bootstrap'

export const NotFound = () => {
  return (
    <Container>
      <Row>
        <Col className='col-md-8 offset-md-2'>
          <div className='card card-outline-secondary'>
            <div className='card-body'>
              <Alert variant='danger'>
                <Alert.Heading>404 - inget här</Alert.Heading>

                <hr />
                <div className='d-flex justify-content-end'></div>
              </Alert>

              <ul className='list-unstyled mt-4 mb-4'>
                <li className='lead text-muted'>
                  {' '}
                  <a
                    href='/'
                    target='_self'
                    rel='noreferrer'
                    className='link-orange'
                  >
                    ▶︎ Börja om från startsidan
                  </a>{' '}
                </li>
              </ul>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className='mt-4 border'></Col>
      </Row>
    </Container>
  )
}

import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'



export const HomePage = () => {
  // render YEAR via JS:
  const date = new Date()
  const year = date.getFullYear()
  // document.getElementById('date').innerHTML = year

  return (
    <Container>
      <Row>
        <Col className='col-md-8 offset-md-2'>
          <div className='card card-outline-secondary'>
            <div className='card-header card-header-orange '>
              <h4 className='lead text-center'>Hey there friend 👋🏻</h4>
            </div>
            <div className='card-body'>
              <p className='lead'>Welcome to:</p>
              <p className='display-4  mt-3'>
                Feedback Collector <br />
              </p>
              <p className='lead'>- a web app, developed to obtain and handle customer feedback. </p>
              <hr />
              <br />
              <p>This is only a start page. All functions are consumed via project URLs.</p>
              <ul className='list-unstyled mt-4 mb-4'>
                <li className='small text-muted'>
                  Brought to you by the geeks at{' '}
                  <a href='https://www.vslrbl.se' target='_blank' rel='noreferrer' className='link-orange'>
                    VSLRBL
                  </a>{' '}
                  © <span>{year}</span>  <img src='https://www.vslrbl.se/favicon-32x32.png' width='16' height='16' alt='Logo' className='mb-1 mr-2' />{' '}
                </li>
              </ul>
            </div>

            <div className='card-body'>
              <ul className='list-unstyled mt-4 mb-4'>
                <li>
                  <cite className='small text-muted'>
                    “Geek - a digital-technology expert or enthusiast, a person who has excessive enthusiasm for and some expertise about a specialized subject
                    or activity”
                  </cite>
                </li>
              </ul>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className='mt-4 border'></Col>
      </Row>
    </Container>
  )
}

import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
// import { Link } from 'react-router-dom'

import logo from './images/logo-300x80.png'
import { Container, Navbar } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
import './App.css'

import { HomePage } from './pages/Home'
import { MasterClassForm } from './pages/MasterClass/MasterClassForm'
import { NotFound } from './components/NotFound'
// import { Footer } from './components/Footer'

console.log('* Hey there friend. We are the robots. We come in peace. 🤖 *')

/* Brought to you by the geeks! */

export const App = () => {
  return (
    <>
      <BrowserRouter>
        <Container fluid>
          <Navbar>
            <Navbar.Brand href='/'>
              <img src={logo} width='150' height='40' className='' alt='Logo' />
            </Navbar.Brand>

            {/* <Navbar.Collapse className='justify-content-end'>
              <Navbar.Text>
                <Link to='/swc-online' className='small text-muted'>
                  [SWC]
                </Link>
              </Navbar.Text>
            </Navbar.Collapse> */}
          </Navbar>
        </Container>

        <Switch>
          <Route path='/' exact>
            <HomePage />
          </Route>

          <Route path='/swc-online'>
            <MasterClassForm />
          </Route>

          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
      {/* <Footer/> */}
    </>
  )
}
